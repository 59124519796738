import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import FormattedText from '../../../01_atoms/FormattedText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';
import styles from './index.module.scss';

const BBFocusArea = ({
  image = null,
  label = '',
  title = '',
  description = '',
  button = null,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-focus-area ${styles['bb-focus-area']} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    id={uuid}
  >
    <div className="container">
      <div className="wrapper">
        {image && (
          <div
            className="image d-none d-md-block"
            style={{ backgroundImage: `url(${image.url})` }}
          />
        )}

        <div className="content">
          {label && <mark className="label">{label}</mark>}

          {title && <h2 className="title rockitt">{title}</h2>}

          {description && (
            <FormattedText className="description d-none d-md-block" text={description} />
          )}

          {button && button.label && button.nextLink && (
            <Link {...button.nextLink}>
              <Button
                tag="a"
                href={button.nextLink.url}
                type="white-green"
                isOutlined
                isMobileBlock
                className="bb-focus-area--button"
              >
                {button.label}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  </div>
);

BBFocusArea.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBFocusArea;
